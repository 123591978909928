@import '~@one/web-components/dist/owc/owc.css';
@import '~@one/icons/dist/one-icons.css';
@import '~@one/design-tokens/css/light/variables.css';

div.tabContents {
    padding-top: 0.5em;
    padding-bottom: 4em;
}

.App {
text-align: left;
margin: 5%;
}

select {
font-size: 16px;
border: 1px solid #eee;
border-bottom: 1px solid #111;
height: 34px;
background-color: #EEE;
}

:not(owc-icon){
    font-family: Roboto, Arial, Helvetica, sans-serif;
}
